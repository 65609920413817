@import '../../design';

/* === Root Calendar === */
.rdp-root {
    display: inline-block;
    font-size: @font-size-UI14;
    color: #101a2c;
    cursor: default;
}

/* === Month Layout === */
.rdp-months.rdp-months {
    gap: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    flex-direction: row;
    padding-bottom: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
}

.rdp-month {
    display: table;
    margin: 24px 15px 0;
    border-spacing: 0;
    border-collapse: collapse;
    user-select: none;
}
.rdp-caption_label.rdp-caption_label {
    width: 100%;
    justify-content: center;
}

/* === Caption === */
.rdp-caption {
    display: table-caption;
    margin-bottom: 0.5em;
    padding: 0 0.5em;
    text-align: center;

    > div {
        font-weight: bold;
        font-size: @font-size-UI18;
    }
}

/* === Weekdays Header === */
.rdp-weekdays.rdp-weekdays {
    margin-top: 1em;
}

.rdp-head_row {
    display: table-row;
}

.rdp-weekday.rdp-weekday {
    display: table-cell;
    padding: 0.5em 0;
    color: #6c7d9f;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    opacity: 1;

    abbr[title] {
        border-bottom: none;
        text-decoration: none;
        cursor: default;
    }
}

/* === Week Grid === */
.rdp-body {
    display: table-row-group;
}

.rdp-week {
    display: table-row;
}

/* === Day Cell === */
.rdp-day.rdp-day {
    display: table-cell;
    width: 33px;
    height: 33px;
    border: 1px solid #dde0eb;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    padding: 0;
    &.rdp-today:not(.rdp-outside) {
        color: inherit;
    }
    &:focus {
        outline: none;
    }
    .rdp-day_button {
        width: 33px;
        height: 33px;
        border: 0;
        border-radius: 0;
    }
}

/* === Day States === */
.rdp-day.rdp-outside {
    color: #8b9898;
    cursor: default;
    border: none;
}

.rdp-day.rdp-disabled {
    color: #6c7d9f;
    cursor: default;
    opacity: 0.5;
}

.rdp-day.rdp-selected:not(.rdp-disabled):not(.rdp-outside) {
    background-color: @primary-blue-500;
    color: white;
    position: relative;
    font-weight: 400;
    font-size: inherit;
}

.rdp-day.rdp-selected.rdp-disabled:not(.rdp-outside) {
    color: white;
    position: relative;
    font-weight: 400;
    font-size: inherit;
}

.rdp-day:hover:not(.rdp-selected):not(.rdp-disabled):not(.rdp-outside) {
    background-color: #dae0eb;
}

/* === Range Styling === */
.rdp-day.rdp-range_start:not(.rdp-disabled) {
    border-radius: 8px 0 0 8px;
}

.rdp-day.rdp-range_end:not(.rdp-disabled) {
    border-radius: 0 8px 8px 0;
}

.rdp-day.rdp-range_middle:not(.rdp-disabled) {
    background-color: #e8edf6;
}

.rdp-month_caption.rdp-month_caption {
    height: auto;
}
.rdp-month_grid {
    margin-top: 1em;
}
.rdp-day.rdp-range_start .rdp-day_button,
.rdp-day.rdp-range_end .rdp-day_button {
    background-color: @primary-blue-500;
}
/* === Navigation === */
.rdp-nav.rdp-nav {
    height: 0;
    left: 0;
    right: 0;
    button {
        z-index: 5;
        position: absolute;
        top: 22px;
        right: 1.5em;
        left: auto;
        display: inline-block;
        margin-top: 2px;
        width: 24px;
        height: 24px;
        background-position: center;
        background-repeat: no-repeat;
        color: #8b9898;
        cursor: pointer;
        svg {
            display: none;
        }
        &:focus {
            outline: none;
        }

        &:hover {
            opacity: 0.8;
        }

        &:disabled {
            display: none;
        }
    }

    .rdp-button_previous {
        background-image: url(baseline-arrow_back-24px.svg);
        left: 1.5em;
    }

    .rdp-button_next {
        background-image: url(baseline-arrow_forward-24px.svg);
    }
}

/* === Footer & Today Button === */
.rdp-footer {
    padding-top: 0.5em;
}

.rdp-button_reset {
    border: none;
    background-color: transparent;
    color: #4a90e2;
    font-size: 0.875em;
    cursor: pointer;
}

.DayPickerInput-OverlayWrapper {
    position: relative;
}

.rdp-root {
    position: absolute;
    left: 0;
    top: 10px;
    z-index: 2;
    background: white;
    border-radius: 8px;
    box-shadow: 0 8px 24px 0 rgba(16, 26, 44, 0.24);
}
